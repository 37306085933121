import React, { useEffect, useState } from 'react';
import Logo from '../logo.jpg';

const Accueil = () => {
  const [linePositions, setLinePositions] = useState([
    { x: 0, y: 0, dx: 2, dy: 1 }, // Line 1: Initial position (x, y), velocity (dx, dy)
    { x: 0, y: 0, dx: 1, dy: -2 }, // Line 2: Initial position (x, y), velocity (dx, dy)
    { x: 0, y: 0, dx: 2, dy: -1 }, // Line 3: Initial position (x, y), velocity (dx, dy)
    { x: 0, y: 0, dx: -2, dy: 2 }, // Line 4: Initial position (x, y), velocity (dx, dy)
  ]);

  useEffect(() => {
    const animationFrame = requestAnimationFrame(animateLines);

    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const animateLines = () => {
    setLinePositions((prevPositions) =>
      prevPositions.map((line) => ({
        x: line.x + line.dx,
        y: line.y + line.dy,
        dx: line.x + line.dx <= 0 || line.x + line.dx >= window.innerWidth - 70 ? -line.dx : line.dx,
        dy: line.y + line.dy <= 0 || line.y + line.dy >= window.innerHeight - 20 ? -line.dy : line.dy,
      }))
    );

    requestAnimationFrame(animateLines);
  };

  const textLines = [
    "Égalité des chances",
    "Confidentialité",
    "Intégrité et Éthique",
    "Collaboration et Qualité",
  ];

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <br />
      <h1>Bienvenue sur le site de l'Agence Rhema</h1>
      <img
        src={Logo}
        alt="Logo"
        style={{
          position: 'absolute',
          left: '50%',
          top: '40%',
          transform: 'translate(-50%, -50%)',
          zIndex: -1,
          width: '600px',
          height: 'auto'
        }}
      />

      {textLines.map((text, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: `${linePositions[index].x}px`,
            top: `${linePositions[index].y}px`,
            color: 'blue',
            fontSize: '18px',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default Accueil;

import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from './components/pages/Accueil';
import Services from './components/pages/Services';
import AProposDeNous from './components/pages/AProposDeNous';
import ContactezNous from './components/pages/ContactezNous';
import NotFound from './components/pages/NotFound';
import './App.css';
import NavBar from './components/NavBar';

function App() {
  return (
    <div className="App">

    <div className="App-container">
      <Header />
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/services" element={<Services />} />
          <Route path="/aproposdenous" element={<AProposDeNous />} />
          <Route path="/contacteznous" element={<ContactezNous />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Footer />
      </div>
    </div>
  );
}

export default App;

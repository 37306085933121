import React from 'react';
import Logo from './logo.jpg'
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__logo">
        <a href="/" className="navbar__logo-link">
    <img src={Logo} alt="Logo" className="navbar__logo" />
  </a>
        </div>
        <div className="footer__links">
          <ul>
            <li><a href="/">Accueil</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contacteznous">Contact</a></li>
          </ul>
        </div>
      </div>
      <div className="footer__copy">
        <p>&copy; {new Date().getFullYear()} Agence Rhema. All rights reserved. Created by Scared Development</p>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function Header() {
  return (
    <div className="header-container">
      <header>
        <div className="contact-info">
          <ul>
            <li>Email : contact@rhema.org</li>
            <li>
              Phone/
              <FontAwesomeIcon icon={faWhatsapp} color="green" /> : + 438 282 9373
            </li>
          </ul>
        </div>

        <div className="social-media">
          <ul>
            
            <li>
              <a href="https://www.facebook.com/photo.php?fbid=130411829761294&id=100083774313710&set=a.128012736667870" className="facebook social">
                <FontAwesomeIcon icon={faFacebook} size="1x" />
              </a>
            </li>
            <li>
              <a href="*" className="twitter social">
                <FontAwesomeIcon icon={faTwitter} size="1x" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/agence-rhema-multi-service-988086259" className="instagram social">
                <FontAwesomeIcon icon={faInstagram} size="1x" />
              </a>
            </li>
          </ul>
        </div>

        <div className="language-choice">
          <ul>
            <li>
              <a href="/" className="language-french">
                Français
              </a>
            </li>
            <li>
              <a href="*" className="language-english">
                English
              </a>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Header;

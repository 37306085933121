import React from 'react';
import '../AProposDeNous.css';





const AProposDenous = () => {
    return (
    <div className='about-us'>

    <div class="image-container"></div>
        
        <div className= 'about-us-profil' id='profil'>
            <hr/>
            <h2>Profil</h2>
            <p>Nous sommes une agence qui offre une variété de services à nos clients aussi bien nationaux qu’internationaux. Nos services incluent des services de conseil, de traduction, de rédaction, de ressources humaines, de formation, d’assermentation des documents et bien plus encore.</p>
            
        </div>
        
        <div className='about-us-mission-et-vision' id='mission-et-vision'>
            <hr/>
            <h2>Mission et Vision</h2>
            <h3><strong>Mission</strong></h3>
            <p>Nous fournissons des services de haute qualité dans tous les domaines d'expertise (Recherche de main d’œuvre qualifiée, accompagner les étudiants dans leurs démarches …) de notre agence. Nous avons développé des partenariats stratégiques avec des fournisseurs et des entreprises pour offrir des solutions complètes à nos clients. Nous proposons des services adaptés aux besoins spécifiques de chaque client.</p>
            <h3><strong>Vision</strong></h3>
            <p>Devenir un partenaire fiable et efficace pour nos clients, en offrant une gamme complète de services de qualité supérieure. Notre mission est axée sur la création des valeurs pour nos clients, en leur offrant des solutions spécifiques, taillées sur mesure et complètes pour répondre à leurs besoins en matière de services.</p>
            
        </div>
        
        
        <div className='about-us-notre-equipe' id='notre-equipe'>
            <hr/>
            <h2>Notre Équipe</h2>
            <p>Notre équipe est composée d'une cohorte de professionnels compétents et passionnés qui travaillent en harmonie pour offrir des services de qualité dans notre agence multiservice. Chacun de nos membres apporte une expertise unique et une expérience significative dans son domaine respectif, ce qui nous permet de fournir une gamme complète de solutions à nos clients.</p>
            <p>Lorsque vous choisissez notre agence multiservice, vous bénéficiez de l'expertise de notre équipe talentueuse et engagée. Nous sommes prêts à collaborer avec vous, à comprendre vos besoins spécifiques et à vous offrir des solutions sur mesure qui dépassent vos attentes. Faites confiance à notre équipe pour vous accompagner dans la réalisation de vos objectifs et pour faire de votre réussite notre priorité.</p>
        </div>

        
        <div className='about-us-clientele' id='clientele'>
            <hr/>
            <h2>Clientèle</h2>
            <p> <ul>
                <li>Personne désirant poursuivre des études au Canada</li>
                <li>Personne désirant travailler temporairement au Canada</li>
                <li>Entreprises et organismes</li>
                <li>Toute personne désirant utiliser nos services</li>
            </ul>
            </p>
        </div>
        
        

        

    </div>)
    
  };

export default AProposDenous;

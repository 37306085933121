import React from 'react';
import './Services.css';


const Services = () => {
  return ( <div className='services-container'>
  
  <div className = 'service-section-img-1' id='objectifs'>
  <hr/>
    <h2>Nos objectifs</h2>

    <p>Aider les entreprises en quête de main d’œuvre qualifiée à l’international à combler leur besoin en personnel.</p>
    <p>Trouver et accompagner les chercheurs d'emploi ayant les qualifications requises pour combler la pénurie de main d’œuvre au Canada en général et au Québec en particulier.</p>
    <p>Assister et accompagner les personnes désireuses de faire des études postsecondaires au Canada dans leurs démarches.</p>
    <p>Soutenir les entreprises nationales et les organismes (quel type d’organismes) en leur fournissant les matériels de bureau.</p>
    <p>Offrir le service de location et d’installation d’équipements d’interprétation simultanée.</p>
    <p>Assermenter les documents pour divers besoins</p>
</div>
  
 
 
 <div className = 'service-section-img-2' id='etudiants-internationaux'>
 <hr/>
    <h2>Les futurs étudiants internationaux</h2>
    <p><i><strong>Conseils et orientation :</strong></i> aider les étudiants à choisir les programmes d'études appropriés en fonction de leurs intérêts et de leurs objectifs de carrière.</p>
    <p><i><strong>Assistance à la candidature :</strong></i> aider les étudiants à remplir les demandes d'admission et à préparer les documents requis.</p>
    <p><i><strong>Services de traduction :</strong></i> fournir des services de traduction pour les documents d'admission et les certificats de scolarité.</p>
    <p><i><strong>Services d'orientation pour l'installation :</strong></i> aider les étudiants à trouver un logement, à se familiariser avec leur nouvel environnement et à s'adapter à leur nouvelle vie d'étudiant.</p>
    <p><i><strong>Services de conseil en carrière :</strong></i> aider les étudiants à planifier leur carrière et à trouver des stages ou des emplois après l'obtention de leur diplôme.</p>
    <p><i><strong>Services de conseil en matière de santé mentale :</strong></i> offrir des services de conseil et de soutien en matière de santé mentale pour aider les étudiants à gérer le stress et les défis liés aux études.</p>
    <p><i><strong>Services d'orientation professionnelle :</strong></i> offrir des services d'orientation professionnelle pour aider les étudiants à acquérir des compétences et à se préparer à entrer sur le marché du travail.</p>

  </div>
  
 

 <div className = 'service-section-img-1' id='chercheurs-emploi'>
 <hr/>
 <h2>Les chercheurs d’emploi</h2>
    <p><i><strong>Coaching et conseil :</strong></i> aider les chercheurs d'emploi à identifier leurs forces et leurs intérêts professionnels, ainsi qu'à élaborer une stratégie de recherche d'emploi efficace.</p>
    <p><i><strong>Préparation des candidatures :</strong></i> aider les chercheurs d'emploi à rédiger CV, lettre de motivation et à préparer des entretiens d'embauche.</p>
    <p><i><strong>Création du réseautage avec des employeurs :</strong></i> fournir des contacts avec des employeurs potentiels et des opportunités d'emploi pertinentes.</p>
    <p><i><strong>Services de conseil en carrière :</strong></i> aider les chercheurs d'emploi à identifier les compétences et les expériences qu'ils peuvent utiliser pour trouver un travail satisfaisant, ainsi qu'à planifier leur carrière.</p>
    <p><i><strong>Formation professionnelle :</strong></i> offrir des programmes de formation professionnelle pour aider les chercheurs d'emploi à acquérir des compétences supplémentaires et à se préparer pour le marché du travail.</p>
    <p><i><strong>Évaluation des compétences :</strong></i> offrir des services d'évaluation des compétences pour aider les chercheurs d'emploi à comprendre leurs forces et leurs faiblesses.</p>
    <p><i><strong>Services de recherche d'emploi en ligne :</strong></i> fournir des ressources en ligne pour aider les chercheurs d'emploi à trouver des offres d'emploi pertinentes et à postuler en ligne.</p>

 </div>
  
  
  
    <div className = 'service-section-img-2' id='entreprises-et-organismes'>
    <hr/>
        <h2>Entreprises et Organismes </h2>

        <p><i><strong>Recrutement international :</strong></i> aider les entreprises à recruter des travailleurs qualifiés à l'international en identifiant les candidats potentiels, en effectuant des présélections et en organisant des entrevues.</p>
        <p><i><strong>Évaluation de compétences :</strong></i> offrir des services d'évaluation des compétences pour aider les entreprises à comprendre les qualifications et les compétences des candidats étrangers.</p>
        <p><i><strong>Assistance à la relocation :</strong></i> aider les travailleurs qualifiés à se relocaliser dans un nouveau pays, en leur fournissant des conseils et des informations sur la vie dans le pays, ainsi que sur les exigences légales et administratives.</p>
        <p><i><strong>Conseil en immigration :</strong></i> aider les travailleurs qualifiés à naviguer dans les systèmes d'immigration des différents pays pour les aider à obtenir les visas et les permis de travail nécessaires.</p>
        <p><i><strong>Formation en langues :</strong></i> offrir des programmes de formation linguistique pour aider les travailleurs qualifiés à s'adapter aux nouvelles langues et cultures.</p>
        <p><i><strong>Évaluation de la culture d'entreprise :</strong></i> aider les entreprises à comprendre la culture d'entreprise des candidats étrangers et à déterminer s'ils seront adaptés à l'environnement de travail.</p>
        <p><i><strong>Gestion de la logistique de l'emploi :</strong></i> aider les entreprises à gérer les formalités administratives et les détails logistiques de l'emploi des travailleurs étrangers, comme l'obtention de permis de travail et de visas, les billets d'avion, l'hébergement, etc.</p>

    </div>

  <div className = 'service-section-img-1' id='autres'>
  <hr/>
  <h2>Achat matériels des bureaux et autres</h2>
    <p><i><strong>Évaluation des besoins :</strong></i> aider les entreprises à évaluer leurs besoins en fournitures de bureau, en fonction de la taille, des activités et du budget.</p>
    <p><i><strong>Sélection des fournisseurs :</strong></i> sélectionner les fournisseurs de fournitures de bureau appropriés, en fonction des besoins de l'entreprise et des prix.</p>
    <p><i><strong>Gestion des commandes :</strong></i> gérer les commandes de fournitures de bureau de l'entreprise, en passant les commandes auprès des fournisseurs sélectionnés et en s'assurant que les fournitures sont livrées à temps.</p>
    <p><i><strong>Gestion des stocks :</strong></i> gérer les stocks de fournitures de bureau de l'entreprise, en s'assurant que les fournitures sont toujours disponibles quand elles sont nécessaires et en évitant les excédents de stocks inutiles.</p>
    <p><i><strong>Économies d'échelle :</strong></i> profiter des économies d'échelle en achetant des fournitures de bureau en gros, ce qui permet d'obtenir des prix plus avantageux.</p>
    <p><i><strong>Écologie :</strong></i> proposer des produits écologiques pour les entreprises qui sont soucieuses de l'environnement.</p>
    
  </div>
  

</div>)
};

export default Services;

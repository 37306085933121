import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './logo.jpg';
import './Navbar.css'


const NavBar = () => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const handleSubItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const mainItems = [
    {
      title: 'Accueil',
      subItems: [],
    },
    {
      title: 'Services',
      subItems: ['Objectifs', 'Étudiants internationaux', 'Chercheurs Emploi', 'Entreprises et Organismes', 'Autres'],
    },
    {
      title: 'À propos de nous',
      subItems: ['Profil', 'Mission et Vision', 'Notre équipe',  'Clientèle'],
    },
    {
      title: 'Contactez nous',
      subItems: ['Testimonies', 'Contact-info'],
    },
  ];

  const isActive = (item) => activeItem === item.title;

  return (
    <nav className="navbar" style={{ backgroundColor: '#e1f1fd' }}>
      <NavLink to="/" exact>
        <img src={Logo} alt="Logo" className="navbar__logo" />
      </NavLink>
      <ul className="navbar__menu">
        <li
          className={`navbar__item ${isActive(mainItems[0]) ? 'active' : ''}`}
          onMouseEnter={() => handleItemClick(mainItems[0].title)}
          onMouseLeave={() => handleItemClick(null)}
        >
          <NavLink to="/" exact activeClassName="active">
            {mainItems[0].title}
          </NavLink>
        </li>
        <li
          className={`navbar__item ${isActive(mainItems[1]) ? 'active' : ''}`}
          onMouseEnter={() => handleItemClick(mainItems[1].title)}
          onMouseLeave={() => handleItemClick(null)}
        >
          <NavLink to="/services" activeClassName="active">
            {mainItems[1].title}
          </NavLink>
          <ul className="navbar__submenu">
            {mainItems[1].subItems.map((subItem, index) => (
              <li key={index} className="navbar__submenu-item">
               <a
                href={`/services#${subItem.toLowerCase().replace(/[éèê]/g, 'e').replace(/\s/g, '-')}`}
                onClick={() => handleSubItemClick(subItem.toLowerCase().replace(/[éèê]/g, 'e').replace(/\s/g, '-'))}
                >
                {subItem}
                </a>
              </li>
            ))}
          </ul>
        </li>
        <li
          className={`navbar__item ${isActive(mainItems[2]) ? 'active' : ''}`}
          onMouseEnter={() => handleItemClick(mainItems[2].title)}
          onMouseLeave={() => handleItemClick(null)}
        >
          <NavLink to="/aproposdenous" activeClassName="active">
            {mainItems[2].title}
          </NavLink>
          <ul className="navbar__submenu">
            {mainItems[2].subItems.map((subItem, index) => (
              <li key={index} className="navbar__submenu-item">
              <a
                href={`/aproposdenous#${subItem.toLowerCase().replace(/[éèê]/g, 'e').replace(/\s/g, '-')}`}
                onClick={() => handleSubItemClick(subItem.toLowerCase().replace(/[éèê]/g, 'e').replace(/\s/g, '-'))}
                >
                {subItem}
              </a>
              </li>
            ))}
          </ul>
        </li>
        <li
          className={`navbar__item ${isActive(mainItems[3]) ? 'active' : ''}`}
          onMouseEnter={() => handleItemClick(mainItems[3].title)}
          onMouseLeave={() => handleItemClick(null)}
        >
          <NavLink to="/contacteznous" activeClassName="active">
            {mainItems[3].title}
          </NavLink>
          <ul className="navbar__submenu">
            {mainItems[3].subItems.map((subItem, index) => (
              <li key={index} className="navbar__submenu-item">
                
                <a
                href={`/contacteznous#${subItem.toLowerCase().replace(/[éèê]/g, 'e').replace(/\s/g, '-')}`}
                onClick={() => handleSubItemClick(subItem.toLowerCase().replace(/[éèê]/g, 'e').replace(/\s/g, '-'))}
                >
                {subItem}
                </a>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;

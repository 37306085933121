import React from "react";
import CarrouselTestimonies from "./CarrouselTestimonies";
import "../ContactezNous.css";

const ContactezNous = () => {
  return (
    <div className="contactez-nous">
      <div className="contactez-nous-testimonies" id="testimonies">
        <CarrouselTestimonies />
      </div>

      <div className="atouts">
        <h2>Nos atouts</h2>
        <p>
          <ul>
            <li>
              - Connaissance de la culture et des besoins du marché du travail
              canadien en général et québécois en particulier.
            </li>
            <li>
              - Bonne connaissance du réseau universitaire québécois et
              canadien.
            </li>
            <li>
              - Expertise éprouvée dans la rédaction des documents
              professionnels et coaching des carrières.
            </li>
            <li>
              - Bonne connaissance des mécanismes d’immigration au Canada.
            </li>
          </ul>
        </p>
        <p>
          <i>Des services aussi bien en francais qu'en anglais!</i>
        </p>
      </div>
      <div className="contactez-nous-contact-info" id="contact-info">
        <h2>Notre Adresse</h2>
        <p>Contactez-nous</p>
        <p>5-6211 Boulevard Roi-René </p>
        <p>Anjou (Québec) H1K 3G4</p>
        <p>Téléphone: +1 438 282 9373 Courriel: contact@agencerhema.org</p>
        <p>
          Fcbk: https://www.facebook.com/agerhems LinkedIn:
          https://www.linkedin.com/feed/
        </p>
        <p></p>
      </div>
    </div>
  );
};

export default ContactezNous;

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const CarrouselTesimonies = () => {
    const responsive = {
        superLargeDesktop: {
          
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
    
        }
      };
    
    
      return(
        <div>
              <h2>Testimonies</h2>
          <Carousel
            responsive={responsive}
            additionalTransfrom={0}
            arrows={false}
            autoPlay
    
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            customTransition="all 1s linear"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            transitionDuration={7000}
          >
                
                <div className='testimonial-slide'>
                    <p>Les efforts soutenus de Rhema meritent toute ma gratitude. Leur expertise m'a servi à trouver un emploi correspondant à mes compétences. Merci à l'équipe. Je vous le recommande. <i>Clarisse N.</i></p>
                </div>
              
              <div className='testimonial-slide'>
                <p>I am incredibly grateful for the exceptional support provided by the multiservice agency. They helped me navigate the process of finding suitable employment in Canada, providing valuable guidance and assistance every step of the way. Their dedication and professionalism made a significant difference in my job search, and I highly recommend their services to anyone seeking reliable career solutions. <i>Nancy O.</i></p>
              </div>
              <div className='testimonial-slide'>
                <p>En tant qu'entreprise, nous avons été extrêmement satisfaits des services offerts par l'agence multiservice. Leur équipe compétente nous a aidés à recruter des candidats qualifiés et à combler nos besoins en ressources humaines. Le processus était fluide et transparent, ce qui a grandement facilité notre recherche de talents. Nous recommandons vivement l'agence multiservice à toute entreprise à la recherche de solutions efficaces. <i>Eric M.</i></p>
              </div>
              <div className='testimonial-slide'>
                <p>J'ai été impressionné par le professionnalisme et l'efficacité de l'agence multiservice. Ils m'ont aidé à préparer mes études au Canada, en fournissant des conseils approfondis et des informations précieuses sur les procédures. Grâce à leur assistance, j'ai pu réaliser mon rêve d'étudier à l'étranger. Je les remercie chaleureusement pour leur accompagnement tout au long de mon parcours. <i>Jean-Claude B.</i></p>
              </div>
              <div className='testimonial-slide'>
                <p>I would like to express my sincere appreciation to the multiservice agency for their outstanding assistance. They helped me with my study plans in Canada, offering comprehensive guidance and valuable insights into the application procedures. Thanks to their support, I was able to fulfill my dream of studying abroad. I wholeheartedly thank them for their continuous support throughout my journey. <i>Verlaine T.</i></p>
              </div>
              
              
          </Carousel>
        </div>
      )
};

export default CarrouselTesimonies;

